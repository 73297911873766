/* custom ssc footer styles */

$footer-height: auto;
$footer-top-grid-margin: (
        small: 2rem,
        medium: 5rem
);

$footer-bottom-grid-margin: (
        small: 5rem,
        medium: 10rem
);

footer.footer {
  height: $footer-height;
  padding-top: 3rem;
  background-color: $ssc-blue;
  color: $ssc-bright-blue;
  margin-top: 0;

  a {
    color: $ssc-bright-blue;
  }

  .top-footer-grid {
    @include xy-grid-container;
    @include xy-grid(horizontal, true);
    @include ssc-top-footer-grid(4);

    @include breakpoint(small) {
      @include ssc-top-footer-grid(1);
    }

    @include breakpoint(medium) {
      @include ssc-top-footer-grid(2);
    }

    h4.widgettitle {
      @include ssc-font(regular, 18, 0);
      text-transform: uppercase;
      color: get-color(menu-white);
    }
  }

  .bottom-footer-grid {
    @include xy-grid-container;
    @include xy-grid(horizontal, true);
    justify-content: space-between;

    padding-bottom: 2rem;
    padding-top: 1rem;
    border-top: 1px solid $ssc-bright-blue;

    .bottom-footer-cell {
      //@include xy-cell(5);
      @include flex;
      @include flex-align(center, middle);

      &.logo-cell {
        @include xy-cell(2);
      }

      @include breakpoint(small down) {
        @include xy-cell(12);

        text-align: center;

        &.logo-cell {
          @include xy-cell(12);
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
      }
    }
  }
  .copyright {
    width: 100%;
    text-align: center;
    font-size: rem-calc(16);
    padding-left: 1rem;
    padding-right: 1rem;
  }
}


hr {
  margin-left: 0;
}

.socialmedia {
  @include fa-icon;

  font-weight: 900;

  & > a {
    margin-right: .5rem;
  }

  display: flex;

  .facebook {
    @extend .fab;
    &:before {
      content: fa-content($fa-var-facebook-f);
    }
  }

  .twitter {
    @extend .fab;
    &:before {
      content: fa-content($fa-var-twitter);
    }
  }

  .email {
    @extend .fas;
    &:before {
      content: fa-content($fa-var-envelope);
    }
  }

  .google {
    @extend .fab;
    &:before {
      content: fa-content($fa-var-google-plus-g);
    }
  }

  .linkedin {
    @extend .fab;
    &:before {
      content: fa-content($fa-var-linkedin-in);
    }
  }

  .bluesky {
    @extend .fab;
    &:before {
      content: fa-content($fa-var-bluesky);
    }
  }

}

input[type="submit"],
input[type="button"] {
  @include ssc-button;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  height: rem-calc(45);
  padding: 1rem;
}

textarea {
  padding: 1rem;
}

input[type="search"],
input[type="search"]:focus {
  float: left;
  width: 85%;
  border-right: none;
  border-radius: 4px 0 0 4px;
}

form.search-form button {
  @include fa-icon;
  @extend .fas;

  &:before {
    content: fa-content($fa-var-search);
    font-weight: 900;
  }

  float: left;
  width: 15%;
  height: rem-calc(36);
  color: $ssc-blue;
  border: 1px solid $ssc-border-color;
  border-left: none;
  border-radius: 0 4px 4px 0;
  font-size: rem-calc(10);
  cursor: pointer;
  background-color: $white;
}

/* Clear floats */
form.search-form::after {
  content: "";
  clear: both;
  display: table;
}

h2.centered {
  text-align: center;
}

hr.centered {
  margin: 0 auto;
}

button.orbit-previous,
button.orbit-next {
  cursor: pointer;
}

span.arrow-icon {
  @include flex;
  @include flex-align(center, middle);
  @include fa-icon;
  @extend .fas;

  margin-left: .7rem;
  margin-right: .7rem;

  @include breakpoint(medium down) {
    display: none;
  }

  &:after {
    content: fa-content($fa-var-long-arrow-alt-right);
    font-size: rem-calc(16);
    font-weight: 900;
  }
}

/*$tooltip-bg-color: rgba(215, 26, 41, 0.8);

.tooltip {
  z-index: 1200;
  max-width: 10rem;
  padding: .3rem .4rem;
  border-radius: 2px;
  background-color: $tooltip-bg-color;
  font-size: .8rem;
  color: #fff;
}

.tooltip.right {
  &:before {
    border-color: transparent $tooltip-bg-color transparent transparent;
  }
}

.tooltip.top {
  &:before {
    border-color: $tooltip-bg-color transparent transparent;
  }
}

.tooltip.bottom {
  &:before {
    border-color: transparent transparent $tooltip-bg-color;
  }
}*/

.wp-block-team-slider {
  @include xy-grid-container();
  margin-top: $global-margin*2;
  &__container {
    @include full-width;
    background: #dde4ec;
    padding-top: $global-padding*2;
    padding-bottom: $global-padding*4;
  }
  &__single-slide-container {
    @include xy-grid();
    @include xy-gutters(0);
  }
  &__image,
  &__desc {
    background: $white;
    @include flex();
    flex-direction: column;
    display: flex;
    justify-content: center;
    @include xy-cell(12,null,0,none);
    @include breakpoint (medium) {
      @include xy-cell(6,null,0,none);
    }
  }
  &__desc {
    padding: $global-padding*2;
    .quote {
      @include ssc-font(light, 30, -.02em);
      color: get-color(primary);
      font-style: italic;
      line-height: normal;
    }
  }
  &__image {
    background-position: center;
    background-size: cover;
    min-height: 360px;
  }
}

button.slick-arrow {
  font-size: 2rem;
  background: $ssc-dark-grey;
  border-radius: 0;
  color: $white;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  z-index: 1;
  top: calc(50% - 1.25rem);
  &:hover {
    cursor: pointer;
    background: $ssc-dark-blue;
  }
  &.slick-prev {
    left: 0;
    @include breakpoint(xlarge) {
      left: -.5rem;
    }
    i {
      margin-right: 6px;
    }
  }
  &.slick-next {
    right: 0;
    @include breakpoint(xlarge) {
      right: -.5rem;
    }
    i {
      margin-left: 6px;
    }
  }
}

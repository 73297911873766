.wp-block-details {
  &.is-style-centered-title {
    summary {
        text-align: center;
    }
  }
  summary {
    font-weight: 500;
  }
  & > summary + * {
    margin-top: 1rem;
  }
}

/**
 * SSC Mixins
 */

@mixin ssc-angle-down() {
  @include fa-icon;
  @extend .fas;

  content: fa-content($fa-var-angle-down);
  font-weight: 600;
}

@mixin ssc-button() {
  //@include button;
  //height: rem-calc(48);
  font-size: rem-calc(16);
  font-weight: 500;
  letter-spacing: .02em;
  text-transform: uppercase;
}

@mixin ssc-button-padding() {
  padding: .85em 1.5em;
}

@mixin ssc-button-outline-padding() {
  padding: calc(.85em - 2px) 1.5em;
}


@mixin ssc-top-footer-grid($cells) {
  @include xy-grid-layout($cells, '.widget', true, $footer-top-grid-margin, margin, right left bottom top);
}

@mixin ssc-font($weight, $size, $letter-spacing) {
  font-size: rem-calc($size);
  letter-spacing: $letter-spacing;

  @if $weight == medium {
    font-weight: 500;
  } @else if $weight == light {
    font-weight: 300;
  }  @else {
    font-weight: 400;
  }
}

@mixin ssc-content-grid($bgcolor) {
  @include xy-grid;
  background-color: $bgcolor;
}

/*
 * Hack for making full-width container (with bgcolor) in gutenberg content
 */
@mixin ssc-stripe($color) {
  $width: 100%;
  width: $width;

  position: relative;
  background-color: $color;
  margin: 0 auto;

  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 99.55vw;
    background-color: $color;
    left: calc(-50vw + #{$width / 2});
    z-index: -1;
  }
}

@mixin full-width {
  width: 100vw!important;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw!important;
  margin-right: -50vw!important;
}

/**
  Custom menu styles.
 */

// remove default background
.menu .active > a,
.menu .is-active > a {
  background: none;
}

// top level menu
.menu,
.lang_selector {
  display: flex;
  gap: 5px;
  margin-block-end: 0;
  a {
    color: get-color(menu-white);
    font-size: rem-calc(15);
    letter-spacing: .025em;
    text-transform: uppercase;
    font-weight: 500;
    &.active {
      color: get-color(primary);
    }
    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
  .lang-sep {
    color: get-color(menu-white);
    transform: scaleY(.7);
  }
}

.dropdown.menu>li>a {
  font-size: 1rem;
  letter-spacing: 1px;
}

// hide lang selector on mobile devices
.lang_selector {
  @include breakpoint(medium down) {
    display: none;
  }
}

.top-bar-right.megamenu {
  .lang_selector {
    display: flex;
    line-height: 1.8;
  }
  @include breakpoint(medium down) {
    position: absolute;
    top: 16px;
    right: 70px;
    line-height: unset;
  }
}

// make accordion menu full width on small devices
@include breakpoint(medium down) {
  .top-bar {
    padding: 0;
  }
}

ul.menu.submenu:not(.is-accordion-submenu) {
  @include border-radius(.3rem);

  .menu-item {
    border-bottom: 1px solid $ssc-grey;

    a {
      color: $ssc-blue;
    }

    &:hover {
      background-color: get-color(primary);

      &:first-child {
        border-top-left-radius: .3rem;
        border-top-right-radius: .3rem;
      }

      &:last-child {
        border-bottom-left-radius: .3rem;
        border-bottom-right-radius: .3rem;
      }

      & > a {
        color: get-color(menu-white);
      }
    }

    &:last-child {
      @include border-none;
    }
  }
}

/* mobile accordion menu */
.menu.accordion-menu {
  position: absolute;
  width: 100%;
  background-color: get-color(menu-white);
  z-index: 9999;
  top: 59px;
  a {
    color: $ssc-blue;
  }

  .menu-item {
    & > a {
      color: $ssc-blue;
    }

    border-bottom: 1px solid $ssc-grey;
    color: $ssc-blue;

    &:hover {
      background-color: get-color(primary);

      & > a {
        color: get-color(menu-white);
      }
    }
  }

  $acc-submenu-margin: 3rem;

  .is-accordion-submenu-item {
    margin-left: $acc-submenu-margin;
    width: calc(100% - #{$acc-submenu-margin});
  }
}

.dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
  left: -90%;
}

footer.footer {
  .menu a {
    display: inline;
    font-size: rem-calc(16);
    text-transform: initial;
    font-weight: 400;
    letter-spacing: 0;
  }

  .bottom-footer-grid {
    ul.menu {

      li + li:before {
        content: ' | ';
      }

      @include breakpoint(xlarge down) {
        @include menu-direction(vertical);

        li + li:before {
          display: none;
        }

      }
    }
  }

  .top-footer-grid {
    ul.menu {
      @include menu-direction(vertical);

      margin-left: -1rem;
    }
  }
}

// Prevent responsive menues form displaying before js loaded
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a:after {
  @include ssc-angle-down;

  display: inline-block;
  float: right;
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a:after {
  @include ssc-angle-down;

  position: relative;
  left: 3px;
  top: -1px;
}

.top-bar-left > ul.menu > li > a {
  font-weight: 600;
}

.top-bar {
  @include breakpoint(large only) {
    padding: 2rem 1rem;
  }
  &.has-meta-nav {
    .meta-nav-container {
      display: flex;
      flex-direction: column-reverse;
    }
    .top-bar-left.logo {
      max-width: $ssc-logo-width;
    }
    .top-bar-right {
      display: flex;
      @include breakpoint(medium down) {
        .meta-menu {
          display: none !important;
        }
        #mega-menu-wrap-main-nav-meta {
          display: none !important;
        }
      }
      .meta-menu > li {
        display: flex;
        align-items: center;
        > a {
          padding: 0 1rem;
          text-transform: none;
        }
      }
    }
  }
}

.top-bar,
.title-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 9999;
  @include breakpoint(large) {
    transition: transform 0.2s linear;
    .scroll-down & {
      transform: translate(0,-100%);
    }
    .scroll-up & {
      transform: none;
    }
  }

}

// safari fix
.menu.large-horizontal {
  width: 100%;
}

.mega-menu-main-nav .top-bar {
  @include breakpoint(large) {
    padding: 1rem;
  }
}

.mega-menu-main-nav .top-bar .top-bar-left.logo  {
  @include breakpoint(medium down) {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 15px
  }
}

.wp-block-columns {
  @include breakpoint(medium) {
    margin-bottom: 2em;
    margin-block-end: 2em !important;
  }
  &.is-style-stretched-columns {
    .wp-block-column {
      @include flex();
      @include flex-direction(column);
      justify-content: space-between;
    }
  }
  // Customer logos class for columns block
  &.customer-logos .wp-block-column.has-background {
    padding: unset;
  }
}

.wp-block-column {
  @include breakpoint(small only) {
    flex-basis: 100% !important;
    &:not(:only-child) {
      flex-basis: 100% !important;
      margin-left: 0;
    }
  }
  &.has-background {
    padding: map-get($grid-margin-gutters,'medium');
    &:not(:last-child) {
      @include breakpoint(small only) {
        margin-bottom: map-get($grid-margin-gutters,'medium');
      }
    }
    p:last-child {}
  }
}

// Columns for backwards compatibility

.stripe.home-products {
  .wp-block-columns {
    @include xy-grid();
    @include xy-gutters($grid-margin-gutters, margin, right left, true);
    .wp-block-column {
      @include xy-cell(auto);
      @include xy-gutters($grid-margin-gutters, margin, right left, false);
      @include breakpoint(small only) {
        flex: auto;
      }
      @include flex-align-self(stretch);
      &.is-vertically-aligned-center {
        @include flex-align-self(middle);
      }
      &.is-vertically-aligned-bottom {
        @include flex-align-self(bottom);
      }
    }
    &.has-background {
      @include xy-grid-container(100%, 20);
      .wp-block-column {
        @include xy-gutters($grid-margin-gutters, margin, right left top bottom, false);
        @include breakpoint(small only) {
          padding: $global-margin 0;
        }
      }
    }
    &.equalizer {
      .wp-block-column {
        @include breakpoint(medium up) {
          @include flex();
          a {
            @include flex(); // for custom service block to keep boxes in equal height
          }
        }
      }
    }

    .wp-block-column:not(:last-child) {
      @include breakpoint(782px down) {
        margin-bottom: 2.75em;
      }
    }

  }
}

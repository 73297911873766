/** slider styles */

button.orbit-next,
button.orbit-previous {
  background-color: $ssc-dark-grey;
}

.orbit-next:active,
.orbit-next:focus,
.orbit-next:hover,
.orbit-previous:active,
.orbit-previous:focus,
.orbit-previous:hover {
  background-color: $ssc-blue;
}

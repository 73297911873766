.cookie-notice-container {
  text-align: left;
}

.cn-buttons-container {
  display: block;
}

.cn-button {
  border-radius: .25rem;
  padding: 8px 16px;
  margin-bottom: 1rem;
  color: #fff;
  float: right;
  font-size: 14px;
  text-transform: uppercase;
}

.cli-tab-footer .wt-cli-privacy-accept-btn {
  background-color: #363b57;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 1rem;
  text-transform: uppercase;
}
.cli-switch input:checked + .cli-slider {
  background-color: #363b57;
}

.cli-plugin-button:focus {
  opacity: unset;
}

a#wt-cli-accept-btn:hover {
  filter: brightness(0.9);
}

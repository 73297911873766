// global styles

// product pages
article.ssc-product,
article.page {

  /* colors for styling gutenberg content with "custom classes" */
  .dark-blue {
    background-color: $ssc-blue;
    color: $white;
  }

  .light-blue {
    background-color: #dce4f1;
  }

  .light-grey {
    background-color: $ssc-grey-background;
  }

  table.wp-block-table.pricing {
    tr:first-child {
      background-color: $ssc-dark-grey;
      color: $white;
    }
  }

  .has-vivid-red-background-color {
    background-color: get-color(primary);
  }

  .entry-content .aligncenter,
  .entry-content img.aligncenter {
    text-align: center;
  }

  .feature-item {
    @include flex;
    @include flex-align(left, stretch);

    background-color: $white;
    margin-bottom: 2rem;
    margin-right: 2rem;

    @include breakpoint(medium down) {
      margin-right: 0;
      height: auto;
    }

    .icon-container {
      @include flex;
      @include flex-align(center, middle);

      min-width: rem-calc(100);

      @include breakpoint(medium only) {
        min-height: rem-calc(130);
      }
      background-color: $ssc-dark-grey;

      img {
        padding: 1rem;

      }
    }

    p {
      font-size: rem-calc(17);
      padding: .5rem 1rem;
      margin-bottom: 0;
      align-self: center;
    }
  }
  ul {
    margin-left: .25rem;
    li:not(.gfield):not(.wp-block-social-link):not(.wp-block-navigation-item), {
      padding-left: 1.5rem;
      margin-bottom: .5rem;
      &:before {
        @include fa-icon;
        @extend .fas;
        font-weight: 600;
        font-size: rem-calc(18);
        margin-left: -1.5rem;
        width: 1.5rem;
        content: fa-content($fa-var-check);
      }
    }
  }


  // remove within several other things
  ul.orbit-container > li,
  li.gfield,
  ol > li {
    &:before {
      display: none !important;
    }
    margin-bottom: .5rem;
  }

  .home-products {
    //text-align: center;

    hr {
      margin-bottom: 2rem;
    }
  }
}

// Blog post page
article.post {
  ul {
    list-style-type: disc;
  }
}

// product-block

.product-block {
  position: relative;
  height: 100%;
  background: $white;
  padding: 1rem;
  ul {
    padding-bottom: .5rem;
    @include breakpoint(782px down) {
      text-align: center;
    }
  }
  .wp-block-button {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    width: 100%;
  }
}

.wp-block-yoast-faq-block {
  .schema-faq-section {
    border: 1px solid $ssc-border-color;
    border-radius: 5px;
    padding: $global-padding;
    margin-bottom: $global-margin;
    .schema-faq-question {
      font-size: 150%;
    }
  }
}

// latest posts block

.wp-block-latest-posts {
  &.is-grid {
    @include xy-grid();
    @include xy-gutters($grid-margin-gutters, margin, right left, false);
    @include xy-gutters($grid-margin-gutters, padding, right left, false);
    li {
      display: flex;
      flex-direction: column;
      border: 1px solid $ssc-border-color;
      border-radius: 4px;
      background: $white;
      @include xy-cell(auto);
      @include xy-gutters($grid-margin-gutters, margin, right left, false);
      @include breakpoint(small only) {
        flex: auto;
      }
      padding-left: 0 !important;
      margin-bottom: $global-margin*2 !important;
      &:before {
        display: none !important;
      }
      @include breakpoint(medium) {
        @include first-child {
          margin-left: 0;
        }
      }
      > a {
        display: block;
        color: $ssc-blue;
        font-weight: $global-weight-bold;
        font-size: rem-calc(20);
        line-height: 1.4;
        margin: 0 $global-margin;
        padding-bottom: $global-padding;
        height: 100%;
      }
    }
  }
  &__post-author {
    margin: 0 $global-margin;
    padding-bottom: $global-padding;
  }
}

.wp-block-group {
  &.fullwidth {
    @include full-width;
  }
  &__inner-container {
    @include xy-grid-container($grid-container,0);
  }
}

.wp-block-social-link:hover {
  transform: unset;
  opacity: .9;
}

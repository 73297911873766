.wp-block-button {
  &__link {
    @include ssc-button;
    background-color: $ssc-blue;
    color: $white;
    transition: opacity .2s;
    &:hover {
      opacity: .9;
      color: $white;
    }
  }
  &.is-style-fill {
    >.wp-block-button__link {
      @include ssc-button-padding();
    }
  }
  &.is-style-outline {
    >.wp-block-button__link {
      border: 2px solid;
      @include ssc-button-outline-padding();
    }
  }
}

article.page .wp-block-button__link, article.ssc-product .wp-block-button__link {
  height: unset;
}

.has-blue-color {
  color: $ssc-blue;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $ssc-blue;
  }
}
.has-blue-background-color {
  background-color: $ssc-blue;
  &.wp-block-button__link {
    background-color: $ssc-blue;
  }
}

.has-medium-blue-color {
  color: $ssc-medium-blue;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $ssc-medium-blue;
  }
}
.has-medium-blue-background-color {
  background-color: $ssc-medium-blue;
  &.wp-block-button__link {
    background-color: $ssc-medium-blue;
  }
}

.has-light-blue-color {
  color: $ssc-light-blue;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $ssc-light-blue;
  }
}
.has-light-blue-background-color {
  background-color: $ssc-light-blue;
  &.wp-block-button__link {
    background-color: $ssc-light-blue;
  }
}

.has-bright-blue-color {
  color: $ssc-bright-blue;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $ssc-bright-blue;
  }
}
.has-bright-blue-background-color {
  background-color: $ssc-bright-blue;
  &.wp-block-button__link {
    background-color: $ssc-bright-blue;
  }
}

.has-dark-blue-color {
  color: $ssc-dark-blue;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $ssc-dark-blue;
  }
}
.has-dark-blue-background-color {
  background-color: $ssc-dark-blue;
  &.wp-block-button__link {
    background-color: $ssc-dark-blue;
  }
}

.has-red-color {
  color: $red;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $red;
  }
}
.has-red-background-color {
  background-color: $red;
  &.wp-block-button__link {
    background-color: $red;
  }
}

.has-black-color {
  color: $black;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $black;
  }
}
.has-black-background-color {
  background-color: $black;
  &.wp-block-button__link {
    background-color: $black;
  }
}

.has-gray-color {
  color: $ssc-gray;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $ssc-gray;
  }
}
.has-gray-background-color {
  background-color: $ssc-gray;
  &.wp-block-button__link {
    background-color: $ssc-gray;
  }
}

.has-light-gray-color {
  color: $light-gray;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $light-gray;
  }
}
.has-light-gray-background-color {
  background-color: $light-gray;
  &.wp-block-button__link {
    background-color: $light-gray;
  }
}

.has-dark-gray-color {
  color: $dark-gray;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $dark-gray;
  }
}
.has-dark-gray-background-color {
  background-color: $dark-gray;
  &.wp-block-button__link {
    background-color: $dark-gray;
  }
}

.has-white-color {
  color: $white;
  &.wp-block-button__link,
  &.wp-block-button__link:hover {
    color: $white;
  }
}
.has-white-background-color {
  background-color: $white;
  &.wp-block-button__link {
    background-color: $white;
  }
}

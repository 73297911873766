.accordion-title {
  &:before {
    left: 0;
    margin-top: -0.55rem;
    color: $white;
    background-color: $red;
    width: rem-calc(18);
    height: rem-calc(18);
    @include breakpoint(large) {
      width: $global-font-size;
      height: $global-font-size;
    }
    border-radius: 100%;
    text-align: center;
  }
  @include breakpoint(large) {
    font-size: $global-font-size
  }
}

.wp-block-ssc-faq {
  margin-top: $global-margin;
  margin-bottom: $global-margin;
  &__link-to-faq-category {
    margin-top: $global-margin;
    > a {
        font-weight: 500;
    }
  }
  .accordion,
  .accordion-content {
    background: transparent !important;
  }
}

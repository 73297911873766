/* Margin and Padding Helper classes */

.margin,
.margin-100 {
  margin: $global-margin;
  &__top {
    margin-top: $global-margin;
  }
  &__bottom {
    margin-bottom: $global-margin;
  }
  &__left {
    margin-left: $global-margin;
  }
  &__right {
    margin-right:$global-margin;
  }
}

.margin-150 {
  margin: $global-margin*1.5;
  &__top {
    margin-top: $global-margin*1.5;
  }
  &__bottom {
    margin-bottom: $global-margin*1.5;
  }
  &__left {
    margin-left: $global-margin*1.5;
  }
  &__right {
    margin-right: $global-margin*1.5;
  }
}

.margin-double,
.margin-200 {
  margin: $global-margin*2;
  &__top {
    margin-top: $global-margin*2;
  }
  &__bottom {
    margin-bottom: $global-margin*2;
  }
  &__left {
    margin-left: $global-margin*2;
  }
  &__right {
    margin-right: $global-margin*2;
  }
}

.padding,
.padding-100 {
  padding: $global-padding;
  &__top {
    padding-top: $global-padding;
  }
  &__bottom {
    padding-bottom: $global-padding;
  }
  &__left {
    padding-left: $global-padding;
  }
  &__right {
    padding-right: $global-padding;
  }
}

.padding-150 {
  padding: $global-padding*1.5;
  &__top {
    padding-top: $global-padding*1.5;
  }
  &__bottom {
    padding-bottom: $global-padding*1.5;
  }
  &__left {
    padding-left: $global-padding*1.5;
  }
  &__right {
    padding-right: $global-padding*1.5;
  }
}

.padding-double,
.padding-200 {
  padding: $global-padding*2;
  &__top {
    padding-top: $global-padding*2;
  }
  &__bottom {
    padding-bottom: $global-padding*2;
  }
  &__left {
    padding-left: $global-padding*2;
  }
  &__right {
    padding-right: $global-padding*2;
  }
}

.no-margin {
  &__top {
    margin-top: 0 !important;
  }
  &__bottom {
    margin-bottom: 0 !important;
  }
  &__left {
    margin-left: 0 !important;
  }
  &__right {
    margin-right: 0 !important;
  }
}

.no-padding {
  &__top {
    padding-top: 0 !important;
  }
  &__bottom {
    padding-bottom: 0 !important;
  }
  &__left {
    padding-left: 0 !important;
  }
  &__right {
    padding-right: 0 !important;
  }
}

.max-width {
  &__medium {
    max-width: map-get($breakpoints,medium);
  }
  &__large {
    max-width: map-get($breakpoints,large);
  }
  &__xlarge {
    max-width: map-get($breakpoints,xlarge);
  }
  &__xxlarge {
    max-width: map-get($breakpoints,xxlarge);
  }
}

.window-height {
  @include breakpoint(medium) {
    min-height: 100vh;
    position: relative;
  }
  &.centered {
    @include flex();
    align-items: center;
    justify-content: center;
  }
}

.absolute-left {
  position: absolute;
  left: 0;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.absolute-top {
  position: absolute;
  top: 0;
}

.absolute-bottom {
  position: absolute;
  bottom: 0;
}

.z-index-minus-1 {
  z-index: -1;
}

.z-index-plus-1 {
  z-index: 1;
}

.width-100 {
  width: 100%;
}

.unset-flex-wrap {
  flex-wrap: unset !important;
}

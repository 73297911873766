.has-small-font-size {
  font-size: rem-calc(14);
}

.has-regular-font-size {
  font-size: $global-font-size;
}
// font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size));
.has-h-4-font-size {
  font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
  @include breakpoint(medium) {
    font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
    line-height: $header-lineheight;
  }
  @include breakpoint(large) {
    font-size: rem-calc(map-deep-get($header-styles, large, h4, font-size));
  }
}

.has-h-3-font-size {
  font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size));
  line-height: $header-lineheight;
  @include breakpoint(medium) {
    font-size: rem-calc(map-deep-get($header-styles, medium, h3, font-size));
  }
  @include breakpoint(large) {
    font-size: rem-calc(map-deep-get($header-styles, large, h3, font-size));
  }
}

.has-h-2-font-size {
  line-height: $header-lineheight;
  font-size: rem-calc(map-deep-get($header-styles, small, h2, font-size));
  line-height: $header-lineheight;
  @include breakpoint(medium) {
    font-size: rem-calc(map-deep-get($header-styles, medium, h2, font-size));
  }
  @include breakpoint(large) {
    font-size: rem-calc(map-deep-get($header-styles, large, h2, font-size));
  }
}

.has-h-1-font-size {
  line-height: $header-lineheight;
  font-size: rem-calc(map-deep-get($header-styles, small, h1, font-size));
  @include breakpoint(medium) {
    font-size: rem-calc(map-deep-get($header-styles, medium, h1, font-size));
  }
  @include breakpoint(large) {
    font-size: rem-calc(map-deep-get($header-styles, large, h1, font-size));
  }
}

.is-uppercase {
  text-transform: uppercase;
}

.is-italic {
  font-style: italic;
}

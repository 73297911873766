/**
 * Styles for content area.
 */

$ssc-grey-border-padding: .5rem;

.content {
  background: $white;
  .page-template-template-full-page & {
    margin-top: rem-calc(60);
    @include breakpoint(large) {
      margin-top: rem-calc(135);
    }
  }
  .content-not-found & {
    margin-top: rem-calc(60);
    padding-top: $global-padding*1.5;
    @include breakpoint(large) {
      margin-top: rem-calc(135);
    }
  }
}


/*
 * make content div to grid container (using global max width).
 */
.content.full-width {
  .inner-content {
    .main {
      //@TODO: get rid of full-container (useless!)
      .full-container {
        width: 100%;

        .content-grid {
          @include xy-grid-container;

          //@include xy-grid;
          //@include flex;
          @include flex-align(center, middle);

          /*.full-cell {
            @include xy-cell(12);
          }*/

          @include breakpoint(medium down) {
            @include flex-direction(column);
          }

          .contact-cell {
            background-color: $white;
            margin-top: 2rem;

            p {
              font-size: rem-calc(17);
              padding-top: 2rem;
              padding-left: 3rem;
              padding-right: 3rem;
            }
          }

          .contact-cell.left,
          .whoweare-cell.left {
            @include xy-cell(6, $gutter-position: left);
            @include flex;
            @include flex-align(center, top);

            & > img {
              //height: 100%;
              object-fit: cover;
              aspect-ratio: 1 / 1;
              padding: 2rem 0 2rem 2rem;
              @include breakpoint(medium down) {
                padding: 2rem 2rem 0 2rem;
                aspect-ratio: unset;
                background-color: $ssc-grey-background;
              }
            }

            @include breakpoint(medium down) {
              @include xy-cell(12, $gutter-position: left right);

              background-color: $white;
            }
          }

          .contact-cell.right,
          .whoweare-cell.right {
            @include xy-cell(6, $gutter-position: right);
            padding: 2rem;
            @include breakpoint(medium down) {
              @include xy-cell(12, $gutter-position: left right);
              margin-top: 0;
              height: auto;
            }

            q {
              @include ssc-font(light, 30, -.02em);

              color: get-color(primary);
              font-style: italic;
              line-height: normal;
            }

            a.contact-button {
              @include ssc-button;
            }

            a.contact-button-white {
              @include ssc-button;

              background-color: $white;
              color: $ssc-dark-grey;
              margin-right: 1rem;

              &:hover {
                color: $ssc-dark-grey;
              }
            }
          }

          .whoweare-cell {
            background-color: $white;
            margin-top: 2rem;
            &__ssc-block-team-loop {
              background-color: $ssc-grey-background;
              margin-left: 0 !important;
              margin-right: 0 !important;
              width: 100% !important;
              @include breakpoint(large) {
                width: 50% !important;
              }
            }

            img {
              height: auto;
            }
          }

          .latest-news-row, .contact-row {
            @include flex;

            @include breakpoint(medium down) {
              flex-direction: column;
            }
          }

          .latest-news-cell {
            @include xy-cell(4);

            background-color: $white;
            margin-top: 2rem;
            border: 1px solid $ssc-border-color;
            border-radius: 4px;

            @include breakpoint(medium down) {
              @include xy-cell(12);
            }

            article {
              @include flex;
              @include flex-direction(column);

              height: unset;
            }

            footer.article-footer {
              @include flex;
              @include flex-direction(column);

              flex-grow: 1;
              justify-content: flex-end;

              & > p {
                border-top: 1px solid $ssc-border-color;
                padding-top: 1rem;
                @include ssc-font(medium, 14, 0);

                a {
                  float: right;
                  text-transform: uppercase;
                }

                span.comments {
                  @include ssc-font(medium, 14, 0);

                  &:before {
                    @include fa-icon;
                    @extend .fas;

                    content: fa-content($fa-var-comment-dots);
                    margin-right: .5rem;
                    color: get-color(primary);
                    font-weight: 400;
                  }
                }
              }
            }

            .article-header {
              margin-top: 2rem;
            }
          }
        }
      }

      .full-container.latest-news {
        background-color: $ssc-grey-background;
      }

      /*.stripe.features-benefits,
      .stripe.home-products {
        @include ssc-stripe($ssc-grey-background);
      }
*/
      .full-container.latest-news,
      .full-container.contact,
      .full-container.whoweare {
        padding-bottom: 5rem;
        padding-top: 2.5rem;
      }

      .full-container.contact {
        background-color: #dde4ec;
      }

      .full-container.contact.team-members {
        background-color: $white;

        .whoweare-cell.right {
          background-color: $ssc-grey-background;
        }
      }
    }
  }
}

.content.with-sidebar {
  @include xy-grid-container;

  .inner-content {
    @include xy-grid;
  }

  .main {
    @include xy-cell(8);
  }
}

.content {
  .inner-content {

    h2.title, h2.title > a {
      @include ssc-font(medium, rem-calc(32), -.02em);
      color: $ssc-blue;
    }

    ul.faq-list {
      margin-left: 0;

      > li {
        padding-bottom: .5rem;
        .accordion-content ul {
          list-style-type: disc;
          li {
            padding-bottom: .5rem;
          }
        }
      }

      + p > a,
      a.show-single-faq {
        color: $ssc-dark-grey;
      }
    }

    /* widget and widget_search outside of sidebar because search_widget is moving on top at certain breakpoint */
    .widget.widget_search {
      padding: 1rem;

      @include breakpoint(medium down) {
        width: 100%;
      }
    }

    .widget {
      background-color: $ssc-background-color;
      padding: $ssc-grey-border-padding;
      margin-bottom: 2rem;

      li > a {
        color: $body-font-color;
      }

      h4.widgettitle {
        @include ssc-font(medium, 24, -.02em);
        text-align: center;
      }

      .hr-wrapper {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    #breadcrumbs {
      @include xy-cell(100%);
      @include ssc-font(regular, 14, 0);

      margin-bottom: 2rem;
    }

    .main {
      @include breakpoint(medium down) {
        @include xy-cell(12);
      }

      header {
        & > h1.entry-title.single-title,
        & > h1.archive-title,
        & > h1.page-title {
          @include ssc-font(medium, 32, -.025em);
          @include breakpoint(small only) {
            @include ssc-font(medium, 24, -.015em);
          }

          color: $ssc-blue;
        }

        & > h1.archive-title,
        & > h1.page-title {
          margin-bottom: 2rem;
        }

        & > h3 {
          font-size: rem-calc(21);

          & > a {
            color: $ssc-blue;
          }
        }
      }

      .sticky-post {
        @include xy-cell(100%);

        margin-bottom: 2rem;
      }

      article {
        section.entry-content {
          & > img {
            margin-bottom: 1rem;
          }
        }

        footer,
        .article-actions {
          .tags {
            display: flex;
            color: get-color(primary);
            font-size: rem-calc(15);
            border-top: 1px solid $ssc-border-color;
            border-bottom: 1px solid $ssc-border-color;
            padding-top: 1rem;
            padding-bottom: 1rem;

            .tags-illustration {
              @include fa-icon;
              @extend .fas;
              
              padding-right: .7rem;

              &:before {
                color: get-color(primary);
                content: fa-content($fa-var-tags);
                font-weight: 900;
              }
            }

            .socialmedia {
              float: right;
              display: flex;
              gap: .5rem;
              align-items: center;

              & > a {
                font-size: 120%;
                color: $ssc-blue;
              }

              @include breakpoint(small down) {
                display: block;
                float: none;
                margin-top: 1rem;
                margin-left: 2rem;
              }
            }
          }
        }

        .byline {
          @include ssc-font(regular, 14, 0);

          //padding-bottom: 1rem;
          //border-bottom: 1px solid $ssc-border-color;
        
        }

        #comments {
          margin-top: 3rem;

          li.comment {
            margin-bottom: 0;
          }

          .media-object {
            border-bottom: 1px solid $ssc-border-color;
            padding-bottom: 1rem;
          }

          .reply-heading {
            @include ssc-font(medium, 26, -.02em);
            color: $ssc-blue;
            margin-bottom: 2rem;
          }

          .comments-title {
            @include ssc-font(medium, 26, -.02em);
            border-bottom: 6px solid $ssc-grey-background;
            padding-bottom: 1rem;

            & > span {
              color: get-color(primary);
            }
          }

          .form-allowed-tags {
            font-size: rem-calc(14);
          }

          article {
            font-size: rem-calc(15);

            header.comment-author {
              & > span {
                @include ssc-font(regular, 18, 0);
                color: $black;
              }
            }
          }

          #respond {
            background-color: $ssc-grey-background;
            padding: $ssc-grey-border-padding;
            margin-bottom: 1rem;

            #commentform {
              border: 1px solid $ssc-border-color;
              background-color: $white;
              padding: 2rem;
              padding-bottom: 1rem;

              .merged-row {
                @include flex;
                justify-content: space-between;
              }

              .form-submit {
                @include flex;
                @include flex-align(right, middle);

                #submit {
                  background-color: $ssc-dark-grey;
                  @include ssc-font(medium, 14, -.025em);
                  margin-top: 1rem;
                }
              }

              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      article.ssc-product,
      article.page {

        // remove padding-top on first h2 element on page and products.
        & > section.entry-content > div.full-container > div.content-grid > div.full-cell {
          & > div.wp-block-columns > div.wp-block-column > h2,
          & > div.home-products > h2 {
            padding-top: 0;
          }
        }
      }
    }

    .archive-grid {
        @include xy-grid;

        .panel {
          @include xy-cell(50%);
          @include breakpoint(medium down) {
            @include xy-cell(100%);
          }

          border: 1px solid $ssc-border-color;
          border-radius: 5px;
          margin-bottom: 2rem;
          &.sticky-post {
            width: 100%;
          }
        }

        .panel.faq {
          border: none;
        }
      }

      .archive-grid,
      .latest-news-cell {
        header,
        section.entry-content,
        footer {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }

        article {
          @include flex;
          @include flex-direction(column);
        }

        footer {
          @include flex;
          @include flex-direction(column);

          flex-grow: 1;
          justify-content: flex-end;

          p.tags {
            font-size: rem-calc(14);
            border-bottom: none;
          }
        }

        // inside archive grid
        .byline {
          margin: 1.5rem;
          padding-bottom: 1rem;

          & > a {
            display: block;
            float: none;
          }
        }
    }

    .sticky-post {
      header {
        padding-top: 1rem;
      }
      p {
        font-size: 110%;
        @include breakpoint(medium down) {
          font-size: inherit;
        }
      }
      .byline {
        margin: unset;
        a {
          @include breakpoint(medium) {
            display: inline-block;
            float: right;
          }
        }
      }
      .tags {
        border-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    .page-navigation {
      margin-bottom: 5rem;
      border-top: 1px solid $ssc-border-color;
      border-bottom: 1px solid $ssc-border-color;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1rem;

      ul {
        margin-bottom: 0;
      }

      a,
      span {
        @include ssc-font(medium, 14, 0);
        color: $ssc-dark-grey;
        background-color: $white;
      }

      a.first,
      a.last,
      a.next,
      a.prev {
        border: 2px solid $ssc-dark-grey;
        border-radius: rem-calc(16);
        text-transform: uppercase;
      }

      a.current {
        border: 2px solid get-color(primary);
        border-radius: rem-calc(16);
      }
    }

    // outside archive grid
    .byline > a {
      float: right;
    }

    .sidebar {
      @include xy-cell(4);

      @include breakpoint(medium down) {
        @include xy-cell(12);
      }

      .widget.widget_recent_entries,
      .widget.widget_ssc_faq {
        h4,
        ul,
        .hr-wrapper {
          background-color: $white;
        }

        h4 {
          margin-bottom: 0;
          padding-top: 1rem;
          border: 1px solid $ssc-border-color;
          border-bottom: none;
        }

        ul {
          padding-left: 2rem;
          padding-right: 2rem;
          padding-bottom: 1rem;
          border: 1px solid $ssc-border-color;
          border-top: none;

          li:not(:first-child) {
            border-top: 1px solid $ssc-border-color;
            padding-top: 1rem;
          }

          li {
            padding-bottom: 1rem;
          }
        }

        hr {
          margin: 0 auto;
        }

        .hr-wrapper {
          border: 1px solid $ssc-border-color;
          border-bottom: none;
          border-top: none;
        }
      }

      .widget.widget_custom_html {
        background: url('../images/triangle.jpg') no-repeat top right;
        background-color: $ssc-blue;
        text-align: center;
        padding: 2rem;
        padding-top: 3rem;

        h4 {
          color: $white;
        }

        hr {
          border-color: $body-font-color;
          margin: 0 auto;
        }

        .contact-button {
          @include ssc-button;
          width: 80%;
          margin: 0 auto;
        }

        p {
          color: #d0d5db;
        }

        strong {
          @include ssc-font(medium, 20, 0);
          color: get-color(menu-white);
        }
      }
    }
  }
}

.blog-next-prev {
  @include xy-grid;

  min-height: rem-calc(200);
  justify-content: center;
  background-color: $ssc-grey-background;
  padding: rem-calc(60) rem-calc(200);
  margin-top: 10rem;

  @include breakpoint(medium down) {
    text-align: center;
    padding: 1rem 3rem;
  }

  .prev,
  .next {
    @include xy-cell(6);
    @include flex;
    @include flex-direction(column);

    @include breakpoint(medium down) {
      @include xy-cell(12);
    }

    height: auto;

    & > p > a {
      font-size: rem-calc(14);
    }

    .title {
      width: 50%;

      @include breakpoint(medium down) {
        width: 100%;
      }

      font-size: rem-calc(18);
      color: $ssc-blue;
    }
  }

  .next {
    margin-left: 0;
    align-items: flex-end;
    text-align: right;


    @include breakpoint(medium down) {
      width: 100%;
      align-items: center;
      text-align: center;
      order: 0;
      padding-bottom: 2rem;
    }

    & > p > a {
      &:after {
        @include fa-icon;
        @extend .fas;

        margin-left: .5rem;
        margin-bottom: 1rem;
        content: fa-content($fa-var-arrow-right);
        font-weight: 900;
      }
    }
  }

  .prev {
    margin-right: 0;
    border-right: 1px solid $ssc-blue;

    @include breakpoint(medium down) {
      width: 100%;
      border-right: none;
      border-top: 1px solid $ssc-blue;
      padding-top: 2rem;
      order: 1;
    }

    & > p > a {
      &:before {
        @include fa-icon;
        @extend .fas;

        margin-right: .5rem;
        content: fa-content($fa-var-arrow-left);
        font-weight: 900;
      }
    }
  }
}

.lead {
  @include breakpoint(small only) {
    font-size: 110%;
  }
}


.content-width__medium {
  max-width: 862px;
}

.center {
  margin: 0 auto !important;
}

.ssc-author-biography {
  &__image {
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
  }
  .company-role,
  .name {
    color: $header-color;
    margin-bottom: 0;
  }
  &__biography {
    margin-top: 1rem;
  }
  &__about {
    margin-bottom: 1rem;
  }
}

.archive.author .title {
  margin-bottom: 1rem;
}

.article-footer {
  .search-results & {
    .tags {
      border-bottom: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
/** Gravity Forms styles */
.gform_wrapper form {

  @include breakpoint(medium up) {
    padding: 1rem 3rem;
  }

  @include breakpoint(small down) {
    ul.gform_fields {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .validation_error {
    display: none;
  }

  li.gfield.gfield_error {
    background-color: transparent;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
  }

  .field_description_below .gfield_description {
    display: flex;
    padding: 0;
  }

  .gfield_error .gfield_label {
    color: inherit;
  }

  li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin-top: 0;
  }

  ul.gfield_radio li:before {
    display: none;
  }

  label.gfield_label {
    font-weight: inherit;
    color: $ssc-font-grey;
    font-size: rem-calc(15);

    span.gfield_required {
      color: $ssc-font-grey;
    }
  }

  .gfield_error input[type="text"].large,
  .gfield_error input[type="email"].large,
  .gfield_error input[type="tel"].large,
  input[type="text"].large,
  input[type="email"].large,
  input[type="tel"].large {
    width: 100%;
    height: rem-calc(39);
    border-radius: 2px;
  }

  .has-tip {
    display: inherit;
  }

  textarea.textarea.small,
  textarea.textarea.medium,
  textarea.textarea.large {
    width: 100%;
  }

  input[type="submit"].gform_button {
    @include ssc-button;

    float: left;
    min-width: rem-calc(180);

    @include breakpoint(small down) {
      margin-right: 1rem;
    }
  }

  @include breakpoint(small down) {
    input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
      line-height: 1;
    }
  }

  ul.gform_fields li.gfield {
    padding-right: 0;
    margin-bottom: 0;
  }

  .gform_footer {
    display: inline-block;
    &:not(.top_label) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.gform_wrapper.gravity-theme {
  p.gform_required_legend {
    visibility: hidden;
    height: 0;
  }
}

// Checkboxes
input[type="checkbox"],
input[type="radio"],
.gform_legacy_markup_wrapper .gfield_checkbox li input,
.gform_legacy_markup_wrapper .gfield_checkbox li input[type=checkbox],
.gform_legacy_markup_wrapper .gfield_checkbox li input,
.gform_legacy_markup_wrapper .gfield_checkbox li input[type=checkbox] {
  display:none !important;
}

[type=checkbox]+label, [type=radio]+label {
  margin-left: 0;
}

input[type="radio"] + label::before  {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.2rem;
  border: 2px solid $ssc-blue;
  background-color: $white;
  display: block;
  content: "";
  float: left;
  margin-right: 5px;
  margin-top: 4px;
}

input[type="radio"]:checked+label::before {
  box-shadow: inset 0px 0px 0px 3px #fff;
  background-color: $ssc-blue;
}

input[type="checkbox"] + label::before {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: $global-radius;
  border: 2px solid $ssc-blue;
  background-color: $white;
  display: block;
  content: "";
  float: left;
  margin-right: 5px;
  margin-top: 4px;
}


input[type="checkbox"]:checked+label::before {
  background-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22 %20width%3D%22442.533px%22%20height%3D%22442.533px%22%20viewBox%3D%220%200%20442.533%20442.533%22%20style%3D%22enable-background%3Anew%200%200%20442.533%20442.533%3B%22 %20xml%3Aspace%3D%22preserve%22%3E %3Cg%3E %3Cpath%20d%3D%22M434.539%2C98.499l-38.828-38.828c-5.324-5.328-11.799-7.993-19.41-7.993c-7.618%2C0-14.093%2C2.665-19.417%2C7.993L169.59%2C247.248 l-83.939-84.225c-5.33-5.33-11.801-7.992-19.412-7.992c-7.616%2C0-14.087%2C2.662-19.417%2C7.992L7.994%2C201.852 C2.664%2C207.181%2C0%2C213.654%2C0%2C221.269c0%2C7.609%2C2.664%2C14.088%2C7.994%2C19.416l103.351%2C103.349l38.831%2C38.828 c5.327%2C5.332%2C11.8%2C7.994%2C19.414%2C7.994c7.611%2C0%2C14.084-2.669%2C19.414-7.994l38.83-38.828L434.539%2C137.33 c5.325-5.33%2C7.994-11.802%2C7.994-19.417C442.537%2C110.302%2C439.864%2C103.829%2C434.539%2C98.499z%22%2F%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3Cg%3E %3C%2Fg%3E %3C%2Fsvg%3E");
  background-size: .8rem;
  background-repeat: no-repeat;
  background-position: 50% .09rem;
}

h3.form-title {
  padding: 2rem 3rem 0;
}

form#gform_20 {
  padding: 0;
}

.ginput_container.ginput_container_consent {
  width: 100% !important;
  display: flex;
  align-items: baseline;
}

.ginput_container.ginput_container_consent label {
  line-height: 1.5;
}

.gform_wrapper.gravity-theme .field_description_below .gfield_consent_description, .gform_wrapper.gravity-theme .gfield_consent_description {
  border: 0;
  padding: 0.5rem 0;
}

.sign-up-form {
  padding: 1rem 0 !important;
}

.sign-up-form_wrapper .gform_required_legend { display: none; }

.sign-up-form .gfield_checkbox label,
.sign-up-form .ginput_container_consent
label {
  font-size: 18px !important;
}

.sign-up-form .ginput_container_consent .gfield_required {
  display: none;
}

.sign-up-form .gfield_checkbox .gchoice {
  display: flex;
  align-items: baseline;
}

.gform_wrapper.gravity-theme .gsection {
  border-bottom: 2px solid #363b57;
  margin-top: 1rem;
}

.gform_wrapper.gravity-theme .gsection h3 {
  font-size: 1.4rem;
}

.gform_wrapper .gfield_required {
  color: inherit;
}

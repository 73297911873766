.wp-block-navigation {
  &:not(.is-vertical) {
    .wp-block-navigation__container {
      // only horizontal gap of 1.2 rem;
      column-gap: 1.2rem;
      row-gap: 0;
    }
    .wp-block-navigation-item {
      &:not(:last-child) {
        &:after {
          content: '|';
          position: relative;
          left: .6rem;
        }
      }
      &:not(.current-menu-item) {
        a.wp-block-navigation-item__content {
          color: $primary-color;
        }
      }
      &.current-menu-item a {
        font-weight: 500;
      }
    }
  }
}

ol.simpletoc-list,
ul.simpletoc-list {
  margin-left: 0;
  & > li {
    &:before {
      display: none !important;
    }
  }
}

.wp-block-simpletoc-toc {
  &.alignfull {
    @include full-width;
    padding: 10px 20px;
    border-top: 1px solid $ssc-medium-blue;
    .simpletoc-list {
      max-width: $grid-container - rem-calc(30);
    }
  }
  &.is-style-horizontal {
    font-size: 85%;
    @include breakpoint(large) {
      font-size: 100%;
    }
    background: $ssc-blue;
    ul, ol {
      &.simpletoc-list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1.2rem;
        row-gap: .5rem;
        justify-content: left;
        @include breakpoint(large) {
          justify-content: center;
        }
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
          margin-right: 0 !important;
          margin-bottom: 0 !important;
          padding-left: 0 !important;
          display: flex;
          align-items: center;
          position: relative;
          transition: all .2s linear;
          &:last-child:after {
            display: none;
          }
          a {
            line-height: 1;
            padding: .3rem .6rem;
            border-radius: 3px;
            background: transparent;
            color: $white;
            transition: all .2s linear;
            &:hover {
                background: $ssc-bright-blue;
            }
          }
          &[data-toc-current] {
            > a  {
              //color: $red;
              background: $ssc-bright-blue;
            }
          }
        }
        &.alignfull {
          margin: 0 auto !important;
        }
      }
    }
  }
  &.sticky {
    position: sticky;
    transition: all .2s linear;
    top: 60px;
    @include breakpoint(large) {
      top: 0;
    }
    left: 0;
    z-index: 2;
    .scroll-up & {
      @include breakpoint(large)  {
        top: 126px;
      }
    }
  }
}

/**
 *  Adding SSC Header styles
 */

$header-height: rem-calc(472);
$ssc-logo-width: rem-calc(169);
$ssc-logo-height: rem-calc(41);
$subheading-font-color: #d5daf3;
$mega-menu-width: map-get($breakpoints, xlarge) - 30px;

#mega-menu-width {
  max-width: rem-calc($mega-menu-width);
  margin: 0 auto;
}

header.header {
  body:not(.page-template-template-full-page) & {
    margin-top: rem-calc(90);
    @include breakpoint(large) {
      margin-top: rem-calc(135);
    }
  }

  @include flex();
  @include flex-direction(column);
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 2rem;

  body:not(.page) & {
    background: url('../images/dotted-map.png') no-repeat center;
    background-color: $ssc-blue;
    background-size: contain, auto;
  }
  background-color: $ssc-blue;
  background-size: contain, auto;

  .heading-container {
    @include xy-grid-container;
    @include xy-grid;
    @include flex;
    width: 100%;
    align-self: center;
    .header-left {
      @include xy-cell(6);

      @include breakpoint(medium down) {
        @include xy-cell(12);
        padding-top: 2rem;
      }
    }
    .header-right {
      @include xy-cell(6);
      text-align: right;

      @include breakpoint(medium down) {
        display: none;
      }
      img {
        max-width: rem-calc(300);
        body.single-post &,
        body.archive.category & {
          max-width: rem-calc(180);
        }
      }
    }

    @include breakpoint(medium down) {
      @include flex-direction(column);
      width: 100%;

    }

    h1,
    strong {
      display: block;
      width: 100%;
      font-size: rem-calc(42);
      @include breakpoint(small only) {
        font-size: rem-calc(28);
      }
      font-weight: 500; // because we are using either h1 or strong (=bold) tag
      line-height: 1;
      letter-spacing: -.05em;
      color: get-color(menu-white);
      margin-bottom: 1rem;
    }

    span {
      font-size: rem-calc(22);
      letter-spacing: -.02em;
      color: $subheading-font-color;
    }

    .wp-block-buttons {
      margin-top: $global-margin;
    }
  }
}


header.home {
  background: url('../images/dotted-map.png') no-repeat center;
  background-color: $ssc-blue;
  background-size: contain, auto;
  @include breakpoint(large) {
    background: url('../images/dotted-map.png') no-repeat center;
    background-color: $ssc-blue;
    background-size: contain, auto;
  }
}

body.blog header {
  .header-right img {
    max-height: rem-calc(120);
  }
}

header.landing-page {
  background-image: none;

  @include breakpoint(medium down) {
    height: 100%;
  }
}

.ssc_logo {
  width: $ssc-logo-width;
  height: $ssc-logo-height;
}

.content-not-found header:not(.article-header) {
  display: none;
}